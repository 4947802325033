import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeart,
  faEnvelope,
  faMapMarkerAlt,
  faCoffee,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import { faSlack } from "@fortawesome/free-brands-svg-icons";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";

const envelopeicon = <FontAwesomeIcon icon={faEnvelope} />;
const locationicon = <FontAwesomeIcon icon={faMapMarkerAlt} />;
const locationicon2 = (
  <FontAwesomeIcon style={{ opacity: "0" }} icon={faMapMarkerAlt} />
);
const hearticon = <FontAwesomeIcon beat icon={faHeart} color="red" />;
const coffeeicon = <FontAwesomeIcon icon={faCoffee} color="grey" />;
const Slackicon = <FontAwesomeIcon icon={faSlack} />;
const CopyIcon = <FontAwesomeIcon icon={faFloppyDisk} />;
const StyledForm = styled.form`
  font-family: "Fielmann Sans", sans-serif;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0% 20%;

  img {
    margin-bottom: 5px;
  }

  .shortener-text {
    margin-bottom: 20px;
  }
`;

const SubmitButton = styled.button`
  border-radius: 0px 5px 5px 0px;
  border-color: grey;
`;

const ShortenerInput = styled.input`
  border-color: grey;

  &:focus {
    box-shadow: none;
    border-color: grey;
  }
`;

const FooterContainer = styled(Container)`
  a {
    color: white;
  }
`;

const StyledLink = styled.a`
  color: white;
  text-decoration: none;
  display: block;
`;

type parsedResponseType = {
  short_id: string;
  long_url: string;
};

const schema = yup
  .object({
    url: yup.string().url().label("Website address").required(),
  })
  .required();

function App() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [resultData, setResultData] = useState<parsedResponseType>();

  const onSubmit = (data: { url: string }) => {
    let session_token = sessionStorage.getItem("token");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + session_token,
      },
      body: JSON.stringify({
        cdn_prefix: window.location.hostname,
        long_url: data.url,
      }),
    };
    fetch("https://fiel.mn/create", requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        let parsedResponse: parsedResponseType;

        try {
          parsedResponse = JSON.parse(response);
          setResultData(parsedResponse);
        } catch (e) {
          return console.error(e);
        }
        if (parsedResponse.short_id !== undefined) handleShow();
      });
  };

  return (
    <>
      <StyledForm /*autoComplete="off"*/ onSubmit={handleSubmit(onSubmit)}>
        <img src="/fielmann_black.svg" alt="fielmann" height="50px" />
        <div className="shortener-text">URL-Shortener</div>

        <div className="input-group">
          <ShortenerInput
            {...register("url")}
            type="text"
            className="form-control"
            placeholder="Website address"
          />
          <div className="input-group-append">
            <SubmitButton className="btn btn-outline-dark" type="submit">
              Submit
            </SubmitButton>
          </div>
        </div>
        {errors.url ? errors.url.message : <></>}
      </StyledForm>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Result</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ wordWrap: "break-word" }}>
            Your URL <strong>{resultData?.long_url}</strong> has been shortened
            to:
          </p>

          <div className="input-group">
            {resultData ? (
              <input type="text" value={resultData.short_id} disabled />
            ) : (
              <></>
            )}

            <Button
              variant="dark"
              onClick={() =>
                navigator.clipboard.writeText(
                  resultData !== undefined ? resultData.short_id : ""
                )
              }
            >
              {CopyIcon}
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <footer className="pt-3 footer bg-dark">
        <FooterContainer>
          <Row>
            <Col lg={3} xs={12}>
              <h3 className="text-white">
                Cloud Native <br />
                Product Family
              </h3>

              <StyledLink
                href="https://fielmann.cloud/cnpf/legacy-services"
                target="_blank"
              >
                Legacy Services
              </StyledLink>
              <p className="pr-5 text-white">
                <StyledLink
                  href="https://fielmann.cloud/cnpf/legacy-perimeter"
                  target="_blank"
                >
                  Legacy Perimeter
                </StyledLink>

                <StyledLink
                  href="https://fielmann.cloud/cnpf/native-cloud-integration"
                  target="_blank"
                >
                  Native Cloud Integration
                </StyledLink>

                <StyledLink
                  href="https://fielmann.cloud/cnpf/identity-access-management"
                  target="_blank"
                >
                  Identity &amp; Access Management
                </StyledLink>

                <StyledLink
                  href="https://fielmann.cloud/cnpf/core-systems"
                  target="_blank"
                >
                  Core Systems
                </StyledLink>

                <StyledLink
                  href="https://fielmann.cloud/cnpf/core-networking"
                  target="_blank"
                >
                  Core Networking
                </StyledLink>

                <StyledLink
                  href="https://fielmann.cloud/cnpf/azure-platform"
                  target="_blank"
                >
                  Azure Platform
                </StyledLink>

                <StyledLink
                  href="https://fielmann.cloud/cnpf/aws-platform"
                  target="_blank"
                >
                  AWS Platform
                </StyledLink>

                <StyledLink
                  href="https://fielmann.cloud/cnpf/fielmann-cloud-engine"
                  target="_blank"
                >
                  Fielmann Cloud Engine
                </StyledLink>

                <StyledLink
                  href="https://fielmann.cloud/cnpf/developer-relations"
                  target="_blank"
                >
                  Developer Relations
                </StyledLink>

                <StyledLink
                  href="https://fielmann.cloud/cnpf/outer-edge-computing"
                  target="_blank"
                >
                  Outer Edge Computing
                </StyledLink>
              </p>
            </Col>

            <Col lg={3} xs={12}>
              <h3 className="text-white">Scopes</h3>
              <p className="pr-3 text-white">
                Cloud Consulting
                <br />
                Operations Automation
                <br />
                Platform &amp; Transformation Engineering
              </p>
            </Col>
            <Col lg={3} xs={12}>
              <h3 className="text-white">Contact</h3>
              <p className="text-white">
                Fielmann AG <br />
                22083 Hamburg, Spohrstrasse 4<br />
                <br />
                {locationicon} Spohrstraße 4, <br />
                {locationicon2} Room 1137 &amp; 1139 <br />
                {envelopeicon}{" "}
                <a href="mailto:nci@fielmann.com">nci@fielmann.com</a>
                <br />
                {Slackicon}{" "}
                <a
                  href="https://fielmann.slack.com/archives/C012N6P69SQ"
                  target="_blank"
                  rel="noreferrer"
                >
                  #cnpf_aua
                </a>
                <br />
              </p>
              <br />
              <img
                className="mb-3"
                src="/fielmann_white.svg"
                alt="fielmann"
                height="35px"
              />
            </Col>
            <Col lg={3} xs={12}>
              <h3 className="text-white">Visit also</h3>
              <p>
                <a
                  href="https://fielmann.atlassian.net/wiki/spaces/NCI/pages/6266257617/AWS+Budgets+-+How+to"
                  target="_blank"
                  rel="noreferrer"
                >
                  AWS Budgets - How to
                </a>
                <br />
                <a
                  href="https://fielmann-sso.awsapps.com/start#/"
                  target="_blank"
                  rel="noreferrer"
                >
                  AWS SSO Login
                </a>
                <br />
                <a
                  href="https://rdweb.wvd.microsoft.com/arm/webclient/index.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Azure Web Apps
                </a>
                <br />
                <a
                  href="https://monitoring.fielmann.net/"
                  target="_blank"
                  rel="noreferrer"
                >
                  ITOA Monitoring
                </a>
                <br />
                <a
                  href="https://fielmann.slack.com/archives/C012N6P69SQ"
                  target="_blank"
                  rel="noreferrer"
                >
                  CNPF in Slack
                </a>
                <br />
                <a
                  href="https://fielmann.atlassian.net/wiki/spaces/NCI/overview"
                  target="_blank"
                  rel="noreferrer"
                >
                  NCI Wiki
                </a>
                <br />
                <a
                  href="https://ea.fielmann.net/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Enterprise Architecture
                </a>
                <br />
              </p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <p>
                <small className="text-white">
                  built by Cloud Native with {hearticon} and {coffeeicon} -{" "}
                  <a
                    href="https://fielmann.slack.com/archives/C012N6P69SQ"
                    target="_blank"
                    rel="noreferrer"
                  >
                    #cnpf_aua
                  </a>
                </small>
              </p>
            </Col>
          </Row>
        </FooterContainer>
      </footer>
    </>
  );
}

export default App;
